import * as React from "react"
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Recaptcha from "react-recaptcha"
import { ResponseType } from './types'
import axios, { AxiosRequestConfig } from 'axios'
import { Helmet } from 'react-helmet';
import {Alert, Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, Snackbar} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import {TextField, RadioGroup, CheckboxWithLabel} from 'formik-mui'
import useSiteMetadata from "../../hooks/SiteMetadata";


interface Props {
    objectcode?: string;
    afdelingscode: string;
    referral: string;
    objects?: {objectcode: string, label: string}[];
}

export const ResponseForm = ({ objects }: Props) => {
    const {afdelingscode, referral, objectcode} = useSiteMetadata()
    const api = `https://fem3t3zzh4.execute-api.eu-central-1.amazonaws.com/production/response`
    const geslachten = [
        ['MAN', 'Man'],
        ['VROUW', 'Vrouw'],
        ['ONBEKEND', 'Onbekend']
    ]
    const spacing = 2;

    return (
        <Formik
            initialValues={{
                privacy: false,
                recaptcha: "",
                financieel_advies: false,
                basis: {
                    objectcode: objectcode,
                    afdelingscode: afdelingscode,
                    referral: referral
                },
                response: {
                    persoon: {
                        email: "",
                        roepnaam: "",
                        geslacht: "ONBEKEND",
                        tussenvoegsel: "",
                        achternaam: "",
                        initialen: "",
                        telefoonnummer: "",
                        woonplaats: "",
                    },
                    responstype: ResponseType.CONTACTAANVRAAG,
                    opmerking: ""
                }
            }}
            onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
                const httpOptions: AxiosRequestConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        'g-recaptcha': values.recaptcha,
                    }
                };

                let to_submit = JSON.parse(JSON.stringify(values));
                to_submit.response.opmerking += '<br /><br />'
                to_submit.response.opmerking += values.financieel_advies ? 'Wel ' : 'Geen'
                to_submit.response.opmerking += 'financieel advies gewenst'

                delete to_submit.privacy
                delete to_submit.financieel_advies
                delete to_submit.recaptcha

                axios.post(api, to_submit, httpOptions)
                    .then(res => {
                        setSubmitting(false);
                        resetForm({})
                        setStatus({
                            message: 'Het formulier is succesvol verstuurd' ,
                            severity: 'success'
                        })
                    })
                    .catch(error => {
                        setStatus({
                            message: error.response.data,
                            severity: 'error'
                        })
                        setSubmitting(false)
                    });
            }}

            validationSchema={Yup.object().shape({
                response: Yup.object().shape({
                    persoon: Yup.object().shape({
                        email: Yup.string()
                            .email('Geen juist mail adres')
                            .required('Verplicht'),
                        roepnaam: Yup.string()
                            .required('Verplicht'),
                        geslacht: Yup.string()
                            .required('Verplicht'),
                        achternaam: Yup.string()
                            .required('Verplicht'),
                        woonplaats: Yup.string()
                            .required('Verplicht'),
                        initialen: Yup.string()
                            .required('Verplicht'),
                        telefoonnummer: Yup.string()
                            .required('Verplicht'),
                    })
                }),
                privacy: Yup.boolean()
                    .oneOf([true], 'Verplicht veld'),
                recaptcha: Yup.string()
                    .required('Verifieer dat je geen robot bent'),
            })}
        >
            {props => {
                const {
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    status
                } = props;
                return (
                    <Box
                        component={'form'}
                        onSubmit={handleSubmit}
                    >
                        <Helmet>
                            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                        </Helmet>

                        <FormControl
                            sx={{
                                marginY: spacing
                            }}
                        >
                            <Field
                                component={RadioGroup}
                                row
                                name={'response.persoon.geslacht'}
                            >
                                {geslachten.map(([value, text], i) =>
                                    <FormControlLabel
                                        key={value}
                                        control={<Radio />}
                                        label={text}
                                        value={value}
                                    />
                                )}
                            </Field>
                        </FormControl>
                        <Grid
                            container
                            spacing={spacing}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Field
                                    component={TextField}
                                    label="Roepnaam"
                                    name="response.persoon.roepnaam"
                                    type="text"
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <Field
                                    component={TextField}
                                    label="Initialen"
                                    name="response.persoon.initialen"
                                    type="text"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={spacing}
                            sx={{
                                marginY: spacing
                            }}
                        >
                            <Grid item xs={12} sm={6}>
                                <Field
                                    component={TextField}
                                    label="Tussenvoegsel"
                                    name="response.persoon.tussenvoegsel"
                                    type="text"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    component={TextField}
                                    label="Achternaam"
                                    name="response.persoon.achternaam"
                                    type="text"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Field
                            component={TextField}
                            label="Email"
                            name="response.persoon.email"
                            type="email"
                            sx={{
                                marginY: spacing
                            }}
                            fullWidth
                        />
                        <Field
                            component={TextField}
                            label="Woonplaats"
                            name="response.persoon.woonplaats"
                            type="text"
                            sx={{
                                marginY: spacing
                            }}
                            fullWidth
                        />
                        <Field
                            component={TextField}
                            label="Telefoonnummer"
                            name="response.persoon.telefoonnummer"
                            type="tel"
                            sx={{
                                marginY: spacing
                            }}
                            fullWidth
                        />
                        {objects &&
                            <Field
                                component={RadioGroup}
                                name={'basis.objectcode'}
                                sx={{
                                    marginY: spacing
                                }}
                            >
                                <FormLabel>Mijn voorkeur gaat uit naar:</FormLabel>
                                {objects.map((x, i) =>
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={x.label}
                                        value={x.objectcode}
                                    />
                                )}
                                <FormControlLabel
                                    control={<Radio />}
                                    label={'Geen voorkeur'}
                                    value={objectcode}
                                    // as={Field} name="basis.objectcode"
                                />
                            </Field>
                        }
                        <Box
                            sx={{
                                marginY: 2
                            }}
                        >
                            <Field
                                component={CheckboxWithLabel}
                                type={'checkbox'}
                                name="financieel_advies"
                                Label={{label: 'Financieel advies gewenst'}}
                            />
                        </Box>
                        <Field
                            label="Als je nog wat meer kwijt wilt..."
                            name="response.opmerking"
                            type="textarea"
                            component={TextField}
                            rows={6}
                            sx={{
                                marginY: spacing
                            }}
                            multiline
                            fullWidth
                        />
                        <Field
                            component={CheckboxWithLabel}
                            name="privacy"
                            type={'checkbox'}
                            sx={{
                                marginY: spacing
                            }}
                            Label={{label: <>
                                    Ik ga akkoord met de <a href="https://www.dna-makelaars.nl/privacyverklaring.pdf" rel="noopener noreferrer" target="_blank">privacy
                                    voorwaarden</a>
                                </>}}
                        />
                        <Box
                            sx={{
                                marginY: 2
                            }}
                        >
                            <Recaptcha
                                sitekey="6LedIZwUAAAAAH0BVbPPcdqLEpu2z0pcKmCz9A72"
                                render="explicit"
                                theme="light"
                                verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                onloadCallback={() => { console.log("done loading!"); }}
                            />
                        </Box>

                        <LoadingButton
                            color="primary"
                            type="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            variant={'contained'}
                            sx={{
                                marginY: spacing
                            }}
                        >
                            Versturen
                        </LoadingButton>
                        <Snackbar
                            open={!!status} autoHideDuration={6000}>
                            {status &&
                                <Alert
                                    severity={status.severity}
                                    sx={{ width: '100%' }}
                                >
                                    {status.message}
                                </Alert>
                            }
                        </Snackbar>
                    </Box>
                )
            }}
        </Formik>
    )
}

export default ResponseForm
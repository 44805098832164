import * as React from "react"
import ResponseForm from "../components/contactform/Form";
import Container from "@mui/material/Container";
import {Box, Grid, Link} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import {Link as GatsbyLink} from "gatsby";
import {sectionPadding} from "../theme";

const SPACING = 8

export default () => {
  return (
      <article>
          <StaticImage
              src={'../images/sfeer/GettyImages-1059234782.jpg'}
              alt={'water'}
              layout={'fullWidth'}
              aspectRatio={1370/405}
               transformOptions={{
                   cropFocus: 'center'
               }}
          />
          <Box
              marginY={sectionPadding}
          >
              <Container
                  maxWidth={'md'}
              >
                  <Box
                      maxWidth={474}
                      marginBottom={SPACING}
                  >
                      <Typography
                          variant={'h2'}
                      >
                          Ik heb belangstelling
                      </Typography>
                      <Typography paragraph>
                          Hieronder schrijft u zich in als geïnteresseerde. Inschrijven voor een woning kan alleen via het inschrijfformulier dat u kunt vinden onder <GatsbyLink to={'/downloads'}>downloads</GatsbyLink>.
                      </Typography>
                  </Box>
                  <Grid
                      container
                      spacing={SPACING}
                  >
                      <Grid item xs={12} md={8}>
                          <ResponseForm
                              afdelingscode={'test'}
                              referral={'test'}
                          />
                      </Grid>
                      <Grid
                          item
                          xs={12}
                          md={4}
                          display={{xs: 'none', md: 'flex'}}
                      >
                          <Box
                              marginBottom={2}
                              maxWidth={136}
                          >
                              <StaticImage
                                  alt={'header'}
                                  src={'../images/logos/dna.png'}
                                  layout={'fullWidth'}
                              />
                          </Box>
                          <address>
                              Antennestraat 86<br />
                              1322 AS Almere <br/>
                              <Link href={'tel:0367370591'}>
                                  036 737 05 91
                              </Link><br />
                              <Link href={'mailto:info@dna-makelaars.nl'}>
                                  info@dna-makelaars.nl
                              </Link><br />
                             <Link
                                 href={'https://www.dna-makelaars.nl'}
                                 target={'_blank'}
                             >
                                 www.dna-makelaars.nl
                             </Link>
                          </address>
                      </Grid>
                  </Grid>
              </Container>
          </Box>
      </article>
  )
}
